import { Setting } from '@element-plus/icons-vue'
import type { RouteRecordRaw } from 'vue-router'

import { DefaultLayout } from '../../constants'

const messageCenterRouter: RouteRecordRaw = {
  path: '/vehicle-order',
  name: 'VehicleOrder',
  component: DefaultLayout,
  redirect: '/vehicle-order/series-manage',
  meta: {
    title: $t('vehicleOrder.name'),
    icon: () => Setting,
  },
  children: [
    {
      path: 'series-manage',
      name: 'SeriesManage',
      meta: {
        title: $t('vehicleOrder.seriesManage.name'),
      },
      component: () => import('@/views/vehicle-order/series-manage/index.vue'),
    },
    {
      path: 'order-manage',
      name: 'OrderManage',
      meta: {
        title: $t('vehicleOrder.orderManage.name'),
      },
      component: () => import('@/views/vehicle-order/order-manage/index.vue'),
    },
    // 交易流水
    {
      path: 'trading-statement',
      name: 'TradingStatement',
      meta: {
        title: $t('vehicleOrder.tradingStatement.name'),
      },
      component: () => import('@/views/vehicle-order/trading-statement/index.vue'),
    },
    {
      path: 'update-config',
      name: 'SeriesUpdateConfig',
      meta: {
        title: $t('commonWords.update'),
        hidden: true,
        singleton: false,
        parentRoutePath: '/vehicle-order/series-manage',
        parentTitle: $t('vehicleOrder.seriesManage.name'),
      },
      component: () => import('@/views/vehicle-order/series-manage/components/update-config.vue'),
    },
    {
      path: 'detail-config',
      name: 'SeriesDetailConfig',
      meta: {
        title: $t('commonWords.detail'),
        hidden: true,
        singleton: false,
        parentRoutePath: '/vehicle-order/series-manage',
        parentTitle: $t('vehicleOrder.seriesManage.name'),
      },
      component: () => import('@/views/vehicle-order/series-manage/components/update-config.vue'),
    },
    {
      path: 'detail-order',
      name: 'SeriesDetailOrder',
      meta: {
        title: $t('commonWords.detail'),
        hidden: true,
        singleton: false,
        parentRoutePath: '/vehicle-order/order-manage',
        parentTitle: $t('vehicleOrder.orderManage.name'),
      },
      component: () => import('@/views/vehicle-order/order-manage/detail.vue'),
    },
  ],
}

export default messageCenterRouter
