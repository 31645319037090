import type { RemoteSelectConfig } from 'nx-components/remote-select'

import { Api_InteriorLangPackSelectPage } from '@/api/__auto__/app-opt/Interior'
import { Api_InteriorAreaDataSelectList, Api_InteriorLangCodeSelectList } from '@/api/__auto__/base-inc-center/Interior'
import { Api_SysRoleList } from '@/api/__auto__/base-inc-center/SysRole'
import {
  Api_InteriorBrandInfoSelectPage,
  Api_InteriorColorInfoSelectPage,
  Api_InteriorSeriesInfoSelectPage,
  Api_InteriorTboxInfoSearchBySn,
  Api_InteriorVehicleInfoSelectByVin,
  Api_InteriorVehicleModelSelectColorDownPage,
  Api_InteriorVehicleModelSelectPage,
} from '@/api/__auto__/tsp-bss/Interior.ts'
import { Api_InteriorAlertCodeExtSelectAll, Api_InteriorDtcCodeSelectPage } from '@/api/__auto__/tsp-alert/Interior.ts'
import * as mallApi from '@/api/__auto__/app-mall-vehicle/Interior'

export const remoteSelectorConfig: RemoteSelectConfig[] = [
  {
    // 系统语言列表
    label: 'sys.langList',
    requestFn: Api_InteriorLangCodeSelectList,
    defaultValueKey: 'lang',
    defaultLabelKey: 'name',
    hasPaginate: false,
  },
  {
    // APP语言列表
    label: 'app.langList',
    requestFn: Api_InteriorLangPackSelectPage,
    defaultValueKey: 'lang',
    defaultLabelKey: 'name',
    hasPaginate: true,
  },
  {
    // 系统角色列表
    label: 'iam.roleList',
    requestFn: Api_SysRoleList,
    echoKey: 'roleId',
    defaultValueKey: 'id',
    defaultQueryKey: 'roleName',
    defaultLabelKey: 'roleName',
    hasPaginate: true,
    optionDisabledFn: (v: any) => v.isAble === false,
  },
  {
    // 车辆品牌列表
    label: 'basics.brandList',
    requestFn: Api_InteriorBrandInfoSelectPage,
    echoKey: 'ids',
    defaultValueKey: 'id',
    defaultQueryKey: 'brandName',
    defaultLabelKey: 'brandName',
    hasPaginate: true,
  },
  {
    // 车系列表
    label: 'basics.vehicleSeriesList',
    requestFn: Api_InteriorSeriesInfoSelectPage,
    echoKey: 'ids',
    defaultValueKey: 'id',
    defaultQueryKey: 'seriesName',
    defaultLabelKey: 'seriesName',
    hasPaginate: true,
  },
  {
    // 车系列表-整车商城
    label: 'basics.mallVehicleSeriesList',
    requestFn: mallApi.Api_InteriorSeriesInfoSelectPage,
    echoKey: 'ids',
    defaultValueKey: 'id',
    defaultQueryKey: 'seriesName',
    defaultLabelKey: 'seriesName',
    hasPaginate: true,
  },
  {
    // 车型列表
    label: 'basics.vehicleModelList',
    requestFn: Api_InteriorVehicleModelSelectPage,
    echoKey: 'ids',
    defaultValueKey: 'id',
    defaultQueryKey: 'modelName',
    defaultLabelKey: 'modelName',
    hasPaginate: true,
  },
  {
    // 车颜色列表
    label: 'basics.vehicleColorList',
    requestFn: Api_InteriorColorInfoSelectPage,
    echoKey: 'ids',
    defaultValueKey: 'id',
    defaultQueryKey: 'colorName',
    defaultLabelKey: 'colorName',
    hasPaginate: true,
  },
  {
    // 车型颜色列表
    label: 'basics.vehicleModelColorList',
    requestFn: Api_InteriorVehicleModelSelectColorDownPage,
    echoKey: 'selectIds',
    defaultValueKey: 'id',
    defaultQueryKey: 'colorName',
    defaultLabelKey: 'colorName',
    hasPaginate: true,
  },
  {
    // TBox列表
    label: 'basics.tBoxList',
    requestFn: Api_InteriorTboxInfoSearchBySn,
    echoKey: 'id',
    defaultValueKey: 'id',
    defaultLabelKey: 'tboxSn',
    defaultQueryKey: 'tboxSn',
    hasPaginate: true,
  },
  {
    // vin列表
    label: 'vehicle.vinList',
    requestFn: (params, extraConfig) => Api_InteriorVehicleInfoSelectByVin(params, extraConfig).then((resp) => {
      // 这个接口特殊，只会在精确命中时返回，所以在这里手动构造返回分页对象，以可以模糊查询
      return {
        list: resp ? [resp] : [],
        pageNum: 1,
        pageSize: 10,
        total: resp ? 1 : 0,
        totalPage: resp ? 1 : 0,
      }
    }),
    echoKey: 'vin',
    defaultValueKey: 'vin',
    defaultQueryKey: 'vin',
    defaultLabelKey: 'vin',
    hasPaginate: true,
    inhibit: true, // 抑制初始化查询
  },
  {
    // 故障码列表
    label: 'basics.dtcCodeList',
    requestFn: Api_InteriorDtcCodeSelectPage,
    echoKey: 'dtcCode',
    defaultValueKey: 'dtcCode',
    defaultQueryKey: 'dtcCode',
    defaultLabelKey: 'dtcCode',
    hasPaginate: true,
  },
  {
    // 省份或者地区列表
    label: 'regionList',
    requestFn: Api_InteriorAreaDataSelectList,
    defaultValueKey: 'internationalCode',
    defaultLabelKey: 'areaName',
    hasPaginate: false,
    defaultParams: {
      areaType: isCnBuild() ? 'zh' : 'other',
    },
  },
  {
    // 报警项列表
    label: 'basics.alertCodeList',
    requestFn: Api_InteriorAlertCodeExtSelectAll,
    echoKey: 'alertCode',
    defaultValueKey: 'alertCode',
    defaultQueryKey: 'alertCode',
    defaultLabelKey: 'alertName',
    hasPaginate: false,
  },
]
