/**
 * @title 整车订单管理接口*
 * @description 本文件由插件自动生成
 */

import request_, { type ExtraConfig } from "@/utils/request";

declare global {}
/**
 * No description
 * @tags 整车订单管理接口
 * @name Reject
 * @summary 驳回取消订单申请
 * @request POST:/interior/vehicleOrder/reject
 */
export function Api_InteriorVehicleOrderReject(
  data: AppMallVehicleVehicleOrderRejectReq,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleReject["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/vehicleOrder/reject`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 整车订单管理接口
 * @name Refund
 * @summary 退款
 * @request POST:/interior/vehicleOrder/refund
 */
export function Api_InteriorVehicleOrderRefund(
  data: AppMallVehicleVehicleOrderRefundReq,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleRefund["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/vehicleOrder/refund`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 整车订单管理接口
 * @name FinalPaymentReview
 * @summary 尾款审核
 * @request POST:/interior/vehicleOrder/finalPaymentReview
 */
export function Api_InteriorVehicleOrderFinalPaymentReview(
  data: AppMallVehicleVehicleOrderReviewReq,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleFinalPaymentReview["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/vehicleOrder/finalPaymentReview`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车系管理接口
 * @name UpdateSeries
 * @summary 修改
 * @request POST:/interior/seriesInfo/update
 */
export function Api_InteriorSeriesInfoUpdate(
  data: AppMallVehicleSeriesInfoUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleUpdateSeries["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/seriesInfo/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车系管理接口
 * @name UpdateStatus
 * @summary 修改状态-上下架
 * @request POST:/interior/seriesInfo/updateStatus
 */
export function Api_InteriorSeriesInfoUpdateStatus(
  data: AppMallVehicleSeriesStatusUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleUpdateStatus["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/seriesInfo/updateStatus`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type VehicleOrderSelectPageParams = Parameters<typeof Api_InteriorVehicleOrderSelectPage>["query"];
}
/**
 * No description
 * @tags 整车订单管理接口
 * @name SelectPage
 * @summary 分页查询
 * @request GET:/interior/vehicleOrder/selectPage
 */
export function Api_InteriorVehicleOrderSelectPage(
  query: AppMallVehicleSelectPageParams,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleSelectPage["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/vehicleOrder/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleOrderSelectByIdParams = Parameters<typeof Api_InteriorVehicleOrderSelectById>["query"];
}
/**
 * No description
 * @tags 整车订单管理接口
 * @name SelectById
 * @summary 查询详情
 * @request GET:/interior/vehicleOrder/selectById
 */
export function Api_InteriorVehicleOrderSelectById(
  query: AppMallVehicleSelectByIdParams,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleSelectById["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/vehicleOrder/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleOrderSelectByIdNonDesParams = Parameters<typeof Api_InteriorVehicleOrderSelectByIdNonDes>["query"];
}
/**
 * No description
 * @tags 整车订单管理接口
 * @name SelectByIdNonDes
 * @summary 查询详情非脱敏
 * @request GET:/interior/vehicleOrder/selectByIdNonDes
 */
export function Api_InteriorVehicleOrderSelectByIdNonDes(
  query: AppMallVehicleSelectByIdNonDesParams,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleSelectByIdNonDes["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/vehicleOrder/selectByIdNonDes`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleOrderGetRefundInfoParams = Parameters<typeof Api_InteriorVehicleOrderGetRefundInfo>["query"];
}
/**
 * No description
 * @tags 整车订单管理接口
 * @name GetRefundInfo
 * @summary 查询退款信息
 * @request GET:/interior/vehicleOrder/getRefundInfo
 */
export function Api_InteriorVehicleOrderGetRefundInfo(
  query: AppMallVehicleGetRefundInfoParams,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleGetRefundInfo["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/vehicleOrder/getRefundInfo`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleOrderExportParams = Parameters<typeof Api_InteriorVehicleOrderExport>["query"];
}
/**
 * No description
 * @tags 整车订单管理接口
 * @name Export
 * @summary 导出
 * @request GET:/interior/vehicleOrder/export
 */
export function Api_InteriorVehicleOrderExport(
  query: AppMallVehicleExportParams,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleExport["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/vehicleOrder/export`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleConfigGetListBySeriesIdParams = Parameters<
    typeof Api_InteriorVehicleConfigGetListBySeriesId
  >["query"];
}
/**
 * No description
 * @tags 车辆配置管理接口
 * @name GetListBySeriesId
 * @summary 分页查询
 * @request GET:/interior/vehicleConfig/getListBySeriesId
 */
export function Api_InteriorVehicleConfigGetListBySeriesId(
  query: AppMallVehicleGetListBySeriesIdParams,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleGetListBySeriesId["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/vehicleConfig/getListBySeriesId`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type SeriesInfoSelectPageParams = Parameters<typeof Api_InteriorSeriesInfoSelectPage>["query"];
}
/**
 * No description
 * @tags 车系管理接口
 * @name SelectPage1
 * @summary 分页查询
 * @request GET:/interior/seriesInfo/selectPage
 */
export function Api_InteriorSeriesInfoSelectPage(
  query: AppMallVehicleSelectPage1Params,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleSelectPage1["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/seriesInfo/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type SeriesInfoSelectByIdParams = Parameters<typeof Api_InteriorSeriesInfoSelectById>["query"];
}
/**
 * No description
 * @tags 车系管理接口
 * @name SelectById1
 * @summary 查询详情
 * @request GET:/interior/seriesInfo/selectById
 */
export function Api_InteriorSeriesInfoSelectById(
  query: AppMallVehicleSelectById1Params,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleSelectById1["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/seriesInfo/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type PayInfoSelectPageParams = Parameters<typeof Api_InteriorPayInfoSelectPage>["query"];
}
/**
 * No description
 * @tags 交易流水管理接口
 * @name SelectPage2
 * @summary 分页查询
 * @request GET:/interior/payInfo/selectPage
 */
export function Api_InteriorPayInfoSelectPage(
  query: AppMallVehicleSelectPage2Params,
  extraConfig: ExtraConfig = {},
): Promise<AppMallVehicleSelectPage2["data"]> {
  return request_({
    url: `/app-mall-vehicle/interior/payInfo/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}
